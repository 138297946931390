import {request} from './request'

// 资讯
export function noticePage(data){
    return request({
        url:"/notice/page",
        method:'post',
        data
    })
}

// 咨询详情
export function noticeDetail(id){
    return request({
        url:`/notice/get/${id}`,
        method:'get',
    })
}

// 分类
export function categoryTreeList(type){
    return request({
        url:`/category/treeList/${type}`,
        method:'post',
    })
}

// 产品列表
export function productPage(data){
    return request({
        url:"product/page",
        method:'post',
        data
    })
}

// 产品详情
export function productDetail(id){
    return request({
        url:`/product/get/${id}`,
        method:'get',
    })
}

// 下载中心
export function resourcePage(data){
    return request({
        url:"/resource/page",
        method:'post',
        data
    })
}

// 下载项详情
export function resourceDetail(id){
    return request({
        url:`/resource/get/${id}`,
        method:'get',
    })
}

// 更新下载次数
export function updateDownCount(id){
    return request({
        url:`/resource/updateDownCount/${id}`,
        method:'post',
    })
}

// 常见问题
export function questionPage(data){
    return request({
        url:"/question/page",
        method:'post',
        data
    })
}

// 问题详情
export function questionDetail(id){
    return request({
        url:`/question/get/${id}`,
        method:'get',
    })
}

