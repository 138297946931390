<template>
  <div class="server">
    <!-- PC -->
    <div
      class="
        productTop
        hidden-xs-only
        wow
        animate__animated animate__fadeInDown
      "
    >
      <img src="../../assets/images/swiper/swiperItem1.png" alt="" />
    </div>
    <!-- mobile -->
    <div
      class="
        mobileProductTop
        hidden-sm-and-up
        wow
        animate__animated animate__fadeInDown
      "
    >
      <img src="../../assets/images/mobile/index/index_1.png" alt="" />
    </div>
    <div class="location hidden-xs-only">
        <div class="section wow animate__animated animate__fadeInLeft">
            <div class="tarbar">
                <span>Location：</span>
                <span @click="handleChange(1)">HOME</span>
                <span>></span>
                <span class="newsCenter">Support</span>
            </div>
        </div>
    </div>
    <div class="industry_content">
      <div class="section">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="Download Center" name="1">
            <div class="first_content wow animate__animated animate__fadeIn">
              <div class="first_content_top">
                <div class="lefts" v-if="catagoryList.length!==0">
                  <el-menu
                    default-active="1"
                    class="el-menu-vertical-demo"
                    @open="handleOpen"
                    @close="handleClose">
                    <div v-for="(item) in catagoryList" :key="item.value">
                      <!-- 一级菜单（没有任何子级菜单）-->
                        <el-menu-item :index="item.value+''" v-if="!item.children">{{item.engName}}</el-menu-item>
                        <!-- 一级菜单（有子级菜单）-->
                        <el-submenu :index="item.value+''" v-else>
                                <div slot="title">{{item.engName}}</div>
                                <!-- 遍历二级菜单容器 -->
                                <div v-for="(i) in item.children" :key="i.value">
                                    <!-- 判断二级菜单（没有三级菜单）-->
                                    <el-menu-item :index="i.value+''" v-if="!i.children" @click="goCategoryTwo(i.value)">{{i.engName}}</el-menu-item>
                                    <!-- 判断二级菜单（有三级菜单）-->
                                    <el-submenu :index="i.value+''" v-if="i.children">
                                      <div slot="title">{{i.engName}}</div>
                                      <el-menu-item class="three" :index="j.value+''" v-for="(j) in i.children" :key="j.value" @click="goCategoryThree(j.value)">{{j.engName}}</el-menu-item>
                                    </el-submenu>
                                </div> 
                        </el-submenu>
                    </div>
                  </el-menu>
                </div>
                <div class="rights hidden-xs-only" v-if="firstList.length!==0">
                  <div class="rights_top">
                    <div class="rights_top_left">
                      <el-input
                        placeholder="Keyword"
                        v-model="firstInfo.name"
                        clearable>
                      </el-input>
                      <el-button type="primary" @click="getResourcePage">Search</el-button>
                    </div>
                    <div class="rights_top_right hidden-xs-only">
                      <span>Altogether {{downLoadTotal}} files</span>
                    </div>
                  </div>
                  <div class="rights_bottom">
                    <div class="item" v-for="(item,index) in firstList" :key="index">
                      <div class="rights_bottom_left" @click="lookDownFile(item)">
                        <div class="rights_bottom_left_top">
                          <div class="titless">{{item.engName}}</div>
                        </div>
                        <div class="rights_bottom_left_bottom">
                          <!-- <div class="look">Look:{{item.look}}</div> -->
                          <div class="download">Download:{{item.downCount}}</div>
                          <div class="time" v-if="item.createTime">Upload Time:{{item.createTime.slice(0,11)}}</div>
                        </div>
                      </div>
                      <div class="rights_bottom_right">
                        <el-button type="primary" @click="downLoad(item)">Download</el-button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mobile_rights hidden-sm-and-up" v-if="mobileFirstList.length!==0">
                  <div class="rights_top">
                    <div class="rights_top_left">
                      <el-input
                        placeholder="Keyword"
                        v-model="mobileFirstInfo.name"
                        clearable>
                      </el-input>
                      <el-button type="primary" @click="getMobileResourcePage">Search</el-button>
                    </div>
                    <div class="rights_top_right hidden-xs-only">
                      <span>Altogether {{mobileDownLoadTotal}} files</span>
                    </div>
                  </div>
                  <div class="rights_bottom">
                    <div class="item" v-for="(item,index) in mobileFirstList" :key="index">
                      <div class="rights_bottom_left" @click="lookDownFile(item)">
                        <div class="rights_bottom_left_top">
                          <div class="titless">{{item.engName}}</div>
                        </div>
                        <div class="rights_bottom_left_bottom">
                          <!-- <div class="look">Look:{{item.look}}</div> -->
                          <div class="download">Download:{{item.downCount}}</div>
                          <div class="time" v-if="item.createTime">Upload Time:{{item.createTime.slice(0,11)}}</div>
                        </div>
                      </div>
                      <div class="rights_bottom_right">
                        <el-button type="primary" @click="downLoad(item)">Download</el-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="first_content_bottom hidden-xs-only" v-if="catagoryList.length!==0">
                <el-pagination
                  :total="downLoadTotal"
                  class="hidden-xs-only"
                  :page-size="firstInfo.pageSize"
                  @current-change="handleCurrentChange"
                  layout="prev, pager, next"
                  background
              >
              </el-pagination>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="Customer Service" name="2">
            <div class="second_content wow animate__animated animate__fadeIn">
              <div class="second_content_top">
                <div class="second_content_top_title">
                  <span>Global Network Service Center</span>
                </div>
                <div class="second_content_top_content">
                  <div class="itemOne">
                    <div class="img"></div>
                    <div class="texts">7x24x365 NOC</div>
                  </div>
                  <div class="itemTwo">
                    <div class="img"></div>
                    <div class="texts">Professional Service Team</div>
                  </div>
                  <div class="itemThree">
                    <div class="img"></div>
                    <div class="texts">Senior CCIE, CCNP HCIE certified engineer</div>
                  </div>
                </div>
              </div>
              <div class="second_content_bottom">
                <div class="second_content_bottom_title">
                  <span>Support Languages</span>
                </div>
                <div class="second_content_bottom_content">
                  <div class="itemOne">
                    <div class="img"></div>
                    <div class="texts">Chinese</div>
                  </div>
                  <div class="itemTwo">
                    <div class="img"></div>
                    <div class="texts">Cantonese</div>
                  </div>
                  <div class="itemThree">
                    <div class="img"></div>
                    <div class="texts">English</div>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="Comme Questions" name="3">
            <div class="third_content hidden-xs-only wow animate__animated animate__fadeIn">
              <div class="third_content_top">
                <div class="rights_top_left">
                  <el-input
                    placeholder="Keyword"
                    v-model="thirdInfo.name"
                    clearable>
                  </el-input>
                  <el-button type="primary" @click="getQuestionPage">Search</el-button>
                </div>
              </div>
              <div class="third_content_middle">
                <img src="../../assets/images/server/top_2.png" alt="">
              </div>
              <div class="third_content_bottom">
                <div class="item" v-for="(item,index) in thirdList" :key="index">
                  <div class="question">
                    <div class="question_top">
                      <div class="question_top_imgOne">
                        <img src="../../assets/images/server/email.png" alt="">
                      </div>
                      <div class="question_one">question</div>
                      <div class="question_text">{{item.engName}}</div>
                    </div>
                  </div>
                  <div class="time" v-if="item.putTime">putTime：{{item.putTime.slice(0,11)}}</div>
                  <div class="answer">
                    <div class="answer_top">
                      <div class="answer_top_imgOne">
                        <img src="../../assets/images/server/message (1).png" alt="">
                      </div>
                      <div class="answer_one">answer</div>
                    </div>
                    <div class="time" v-if="item.answerTime">answerTime：{{item.answerTime.slice(0,11)}}</div>
                    <div class="answer_text">{{item.engAnswer}}</div>
                  </div>
                </div>
              </div>
              <div class="first_content_bottom" v-if="thirdList.length">
                <el-pagination
                  :total="questionTotal"
                  class="hidden-xs-only"
                  :page-size="thirdInfo.pageSize"
                  @current-change="handleThirdCurrentChange"
                  layout="prev, pager, next"
                  background
              >
              </el-pagination>
              </div>
            </div>
            <div class="mobile_third_content hidden-sm-and-up wow animate__animated animate__fadeIn">
              <div class="third_content_top">
                <div class="rights_top_left">
                  <el-input
                    placeholder="Keyword"
                    v-model="mobilethirdInfo.name"
                    clearable>
                  </el-input>
                  <el-button type="primary" @click="getMobileQuestionPage">Search</el-button>
                </div>
              </div>
              <div class="third_content_middle">
                <img src="../../assets/images/server/top_2.png" alt="">
              </div>
              <div class="third_content_bottom">
                <div class="item" v-for="(item,index) in mobilethirdList" :key="index">
                  <div class="question">
                    <div class="question_top">
                      <div class="question_top_imgOne">
                        <img src="../../assets/images/server/email.png" alt="">
                      </div>
                      <div class="question_one">question</div>
                      <div class="question_text">{{item.engName}}</div>
                    </div>
                  </div>
                  <div class="time" v-if="item.putTime">putTime：{{item.putTime.slice(0,11)}}</div>
                  <div class="answer">
                    <div class="answer_top">
                      <div class="answer_top_imgOne">
                        <img src="../../assets/images/server/message (1).png" alt="">
                      </div>
                      <div class="answer_one">answer</div>
                    </div>
                    <div class="time" v-if="item.answerTime">answerTime：{{item.answerTime.slice(0,11)}}</div>
                    <div class="answer_text">{{item.engAnswer}}</div>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { categoryTreeList,questionPage,resourcePage,updateDownCount } from '../../api/management'
export default {
  data() {
    return {
      isPc:true,
      url:process.env.VUE_APP_WEB_URL,
      active:'',
      activeNameTwo:'1',
      activeName: this.$route.query.id,
      downLoadTotal:null,
      firstInfo:{
        name:'',
        pageSize:5,
        current:1,
        categoryId:'',
        displaySeen:true
      },
      firstList:[],
      thirdInfo:{
        name:'',
        pageSize:6,
        current:1,
        displaySeen:true
      },
      thirdList:[],
      questionTotal:null,
      catagoryList:[],
      type:3,

      // 手机
      mobileFirstInfo:{
        name:'',
        pageSize:1000,
        current:1,
        categoryId:'',
        displaySeen:true
      },
      mobileDownLoadTotal:null,
      mobileFirstList:[],
      // 常见问题
      mobilethirdInfo:{
        name:'',
        current:1,
        pageSize:1000,
        displaySeen:true
      },
      mobilethirdList:[],
      mobilequestionTotal:null,
    };
  },
  created() {
    // 下载的分类
    this.getCategoryTreeList()
    // // 常见问题
    // this.getQuestionPage()
    // // 默认展示全部的下载列表
    // this.getResourcePage()

  },
  mounted() {
    this.getPCorMobile()

  },
  methods:{
    getPCorMobile() {
          if(/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
            this.isPc = false
            this.getMobileResourcePage()
            // this.getMobileQuestionPage()
            //  window.addEventListener('scroll', this.Scrollbottom, true);
          } else {
            this.isPc = true
             // 常见问题
            //  this.getQuestionPage()
             // 默认展示全部的下载列表
             this.getResourcePage()
          }
    },
    // 下载中心分类
    getCategoryTreeList() {
      axios({
        method: "post",
        url: this.url+`/category/treeList/`+this.type,
      }).then(res => {
         this.catagoryList = res.data
      })
    },
    // 常见问题 PC
    async getQuestionPage(){
      const res = await questionPage(this.thirdInfo)
      // console.log(res);
      this.questionTotal = res.data.total
      this.thirdList = res.data.records
    },
    // 常见问题 mobile
    async getMobileQuestionPage(){
      const res = await questionPage(this.mobilethirdInfo)
      this.mobilequestionTotal = res.data.total
      this.mobilethirdList = res.data.records
    },
    handleChange(num) {
      this.$store.commit("changeNum", num);
      this.$router.push('/index')
    },
    handleClick(tab) {
      // console.log(tab);
    },
    getClick(items) {
      this.active = items.id
    },
    handleOpen(key, keyPath) {
      // console.log(key);
      // console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      // console.log(key, keyPath);
    },
    handleCurrentChange(value) {
      this.firstInfo.current = value
      this.getResourcePage()
    },
    // 没有三级，点击的是二级
    goCategoryTwo(value) {
      if(this.isPc) {
        this.firstInfo.categoryId = value
        this.getResourcePage()
      } else {
        this.mobileFirstInfo.categoryId = value
        this.getMobileResourcePage()
      }
    },
    // 有三级，点击的是三级
    goCategoryThree(value) {
      if(this.isPc) {
        this.firstInfo.categoryId = value
        this.getResourcePage()
      } else {
        this.mobileFirstInfo.categoryId = value
        this.getMobileResourcePage()
      }
    },
    // 获取下载中心列表 PC
    async getResourcePage() {
      const res = await resourcePage(this.firstInfo)
      // console.log(res);
      this.firstList = res.data.records
      this.downLoadTotal = res.data.total
    },
    // 获取下载中心列表 mobile
    async getMobileResourcePage() {
      const res = await resourcePage(this.mobileFirstInfo)
      // console.log(res);
      this.mobileFirstList = res.data.records
      this.mobileDownLoadTotal = res.data.total
    },
    // 常见问题
    handleThirdCurrentChange() {

    },
    getBlob(url) {
      return new Promise(resolve => {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.responseType = 'blob';
        xhr.onload = () => {
          if (xhr.status === 200) {
            resolve(xhr.response);
          }
        };
        xhr.send();
      });
    },
    /**
     * 保存 blob
     * filename 想要保存的文件名称
     */
    saveAs(blob, filename) {
      if (window.navigator.msSaveOrOpenBlob) {
        navigator.msSaveBlob(blob, filename);
      } else {
        const link = document.createElement('a');
        const body = document.querySelector('body');
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        // fix Firefox
        link.style.display = 'none';
        body.appendChild(link);
        link.click();
        body.removeChild(link);
        window.URL.revokeObjectURL(link.href);
      }
    },
    /**
     * 下载
     * @param  {String} url 目标文件地址
     * @param  {String} filename 想要保存的文件名称
     */
    // 下载按钮
    downLoad(item) {
      let url = this.url+item.file.fileUrl
      let filename = item.file.fileName
      let id = item.id
      this.getBlob(url).then(blob => {
        this.saveAs(blob, filename);
      });
      this.getUpdateDownCount(id)
    },
    // 更新下载次数
    async getUpdateDownCount(id) {
      const res = await updateDownCount(id)
      if(res.code==1) {
        this.getResourcePage()
      }
    },
    // 查看
    lookDownFile(item) {
      let url = this.url+item.file.fileUrl
      window.open(url,'_blank'); 
    }
  }
};
</script>
<style lang="scss">
.server {
  padding-top: 92px;
  .productTop {
    img {
      width: 100%;
      height: 482px;
    }
  }
  .location {
    padding: 50px 0;
    .section {
        font-size: 18px;
        .tarbar {
            cursor: pointer;
            .newsCenter {
                color: #197dbe;
            }
        }
    }
  }
  .industry_content{
    .section {
      .el-tabs {
        .el-tabs__header {
          margin: 0 0 60px;
          line-height: 90px;
          .el-tabs__nav-wrap {
            .el-tabs__nav-scroll {
              .el-tabs__nav{
                .el-tabs__item {
                  font-size: 30px;
                  padding: 0 60px
                }
                #tab-1 {
                  padding-left: 0!important;
                }
                .el-tabs__active-bar {
                  height: 4px;
                  background: linear-gradient(to right, #25aae1, #197dbe);
                }
                .is-active {
                  display: inline-block;
                  background-image: linear-gradient(
                    to right,
                    #25a9e0,
                    #187aba
                  ); //最后三行为文字渐变CSS
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
                }
              }
            }
          }
        }
        .el-tabs__content {
          .first_content {
            .first_content_top {
              display: flex;
              justify-content: space-between;
              .lefts {
                // margin-top: 40px;
                width:306px;
                .el-collapse {
                    border: 1px solid #dcdcdc!important;
                    .el-collapse-item {
                        width: 304px;
                        .el-collapse-item__header{
                          display: flex;
                          justify-content: center;
                          width: 100%;
                          font-size: 24px;
                          height: 80px;
                          .el-collapse-item__arrow  {
                              display: none;
                          }
                        }
                        .el-collapse-item__header.is-active {
                            border-bottom-color: #dcdcdc;
                        }
                    }
                    .is-active {
                      color: #197DBE;
                    }
                    .el-collapse-item__wrap{
                            border: none;
                            .el-collapse-item__content {
                                padding-bottom: 0;
                                div {
                                    padding-left: 20px;
                                    font-size: 24px;
                                    line-height: 80px;
                                    cursor: pointer;
                                    border-bottom: 1px solid #dcdcdc!important;
                                }
                                .selected {
                                    color: #197DBE;
                                }
                            }
                    }
                }
                .el-menu {
                  border: solid 1px #e6e6e6;
                  // border-bottom: none;
                  .el-submenu {
                    .el-submenu__title {
                      height: 60px;
                      line-height: 60px;
                      font-size: 22px;
                      border-bottom: solid 1px #e6e6e6;
                      .el-submenu__icon-arrow {
                        display: none;
                      }
                    }
                    .el-submenu__title:last-child {
                      border-bottom: none;
                    }
                    .el-submenu__title:hover {
                      background: none;
                      color: #197DBE;
                    }
                    .el-menu {
                      // border-left: none;
                      // border-right: none;
                      // border-bottom: none;
                      border: none;
                      .el-menu-item {
                        height: 60px;
                        line-height:60px;
                        font-size: 22px;
                        border-bottom: 1px solid #e6e6e6;
                      }
                      .el-submenu {
                        border-bottom: solid 1px #e6e6e6;
                        .el-menu {
                          .el-menu-item {
                            height: 60px;
                            line-height:60px;
                            font-size: 22px;
                            border-bottom: 1px solid #e6e6e6;
                          }
                          .el-menu-item:hover {
                            background: none;
                            color: #197DBE;
                          }
                          .el-menu-item:nth-child(2) {
                            border-bottom: none;
                          }
                          .is-active {
                            color: #197DBE;
                          }
                        }
                      }
                      .el-submenu:nth-child(3) {
                        border: none;
                      }
                    }
                  }
                  .el-menu-item {
                    font-size: 22px;
                  }
                }
              }
              .rights {
                width: 876px;
                border: 1px solid #E5E5E5 ;
                padding: 0 25px;
                .rights_top {
                  padding: 40px 0;
                  display: flex;
                  justify-content: space-between;
                  border-bottom: 1px solid #e7e7e7;
                  .rights_top_left {
                    width: 387px;
                    .el-input {
                      max-width: 300px;
                      height: 58px;
                      font-size: 20px;
                      .el-input__inner {
                      text-align: center;
                         height: 58px;
                      }
                    }
                    .el-button--primary {
                      padding:0;
                      width: 84px;
                      height: 58px;
                      font-size: 20px;
                      color: #FFF;
                      background: linear-gradient(to top right, #25aae1, #197dbe);
                    }

                  }
                  .rights_top_right {
                    padding-right: 40px;
                    display: flex;
                    flex-direction: column-reverse;
                    span {
                      font-size: 18px;
                     display: inline-block;
                     background-image: linear-gradient(
                       to right,
                       #25a9e0,
                       #187aba
                     ); //最后三行为文字渐变CSS
                     -webkit-background-clip: text;
                     -webkit-text-fill-color: transparent;
                    }

                  }
                }
                .rights_bottom {
                  .item {
                    padding:20px 40px 20px 0;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-bottom:1px solid #e7e7e7 ;
                    .rights_bottom_left {
                      .rights_bottom_left_top {
                        .titless {
                          font-size: 24px;
                        }
                      }
                      .rights_bottom_left_bottom {
                        margin-top: 8px;
                        display: flex;
                        // justify-content: space-between;
                        .look, .download, .time{
                          font-size: 16px;
                        }
                        .download {
                          margin-right: 15px;
                        }
                      }
                    }
                    .rights_bottom_right {
                      .el-button--primary {
                        width: 80px;
                        height: 48px;
                        font-size: 16px!important;
                        color: #3699FF;
                        background: #fff;
                        padding: 0;
                      }
                    }
                  }
                  .item:nth-child(5n) {
                    border-bottom:none ;
                  }
                  .item:hover {
                    .rights_bottom_left {
                      color: #235E9D;
                    }
                    .rights_bottom_right {
                      .el-button--primary {
                        color: #FFF;
                        background: linear-gradient(to top right, #25aae1, #197dbe);
                      }
                    }

                  }
                }
              }
            }
            .first_content_bottom {
              padding: 60px 0;
              .el-pagination {
                .btn-prev, .btn-next {
                  display: none;
                }
                .el-pager {
                  .number {
                    height: 48px!important;
                    line-height: 48px!important;
                    min-width: 48px!important;
                    font-size: 18px!important;
                  }
                }
              }
            }
          }
          .second_content {
            .second_content_top {
              .second_content_top_title {
                text-align: center;
                margin-bottom: 60px;
                span {
                  font-size: 36px;
                  display: inline-block;
                  background-image: linear-gradient(
                    to right,
                    #25a9e0,
                    #187aba
                  ); //最后三行为文字渐变CSS
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
                }
              }
              .second_content_top_content {
                display: flex;
                justify-content: space-between;
                .itemOne {
                  width: 310px;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  .img {
                    background: url("../../assets/images/server/index_1.png") no-repeat center center;
                    background-size: cover;
                    width: 144px;
                    height: 144px;
                    transition: all 500ms linear;
                    margin-bottom: 30px;
                  }
                  .texts {
                    font-size: 34px;
                    line-height: 48px;
                  }
                }
                .itemOne:hover {
                  .img {
                    background: url("../../assets/images/server/indexSelected_1.png") no-repeat center center;
                    background-size: cover;
                  }
                  .texts {
                    display: inline-block;
                    background-image: linear-gradient(
                      to right,
                      #25a9e0,
                      #187aba
                    ); //最后三行为文字渐变CSS
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                  }
                }
                .itemTwo {
                  width: 310px;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  .img {
                    background: url("../../assets/images/server/index_2.png") no-repeat center center;
                    background-size: cover;
                    width: 144px;
                    height: 144px;
                    transition: all 500ms linear;
                    margin-bottom: 30px;

                  }
                  .texts {
                    font-size: 34px;
                    line-height: 48px;
                  }
                }
                .itemTwo:hover {
                  .img {
                    background: url("../../assets/images/server/indexSelected_2.png") no-repeat center center;
                    background-size: cover;
                  }
                  .texts {
                    display: inline-block;
                    background-image: linear-gradient(
                      to right,
                      #25a9e0,
                      #187aba
                    ); //最后三行为文字渐变CSS
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                  }
                }
                .itemThree {
                  width: 310px;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  .img {
                    background: url("../../assets/images/server/index_3.png") no-repeat center center;
                    background-size: cover;
                    width: 144px;
                    height: 144px;
                    transition: all 500ms linear;
                    margin-bottom: 30px;

                  }
                  .texts {
                    font-size: 34px;
                    line-height: 48px;
                  }
                }
                .itemThree:hover {
                  .img {
                    background: url("../../assets/images/server/indexSelected_3.png") no-repeat center center;
                    background-size: cover;
                  }
                  .texts {
                    display: inline-block;
                    background-image: linear-gradient(
                      to right,
                      #25a9e0,
                      #187aba
                    ); //最后三行为文字渐变CSS
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                  }
                }
              }
            }
            .second_content_bottom {
              padding-bottom: 60px;
              .second_content_bottom_title {
                text-align: center;
                margin: 60px 0;
                span {
                  font-size: 36px;
                  display: inline-block;
                  background-image: linear-gradient(
                    to right,
                    #25a9e0,
                    #187aba
                  ); //最后三行为文字渐变CSS
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
                }
              }
              .second_content_bottom_content {
                display: flex;
                justify-content: space-between;
                .itemOne {
                  width: 310px;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  .img {
                    background: url("../../assets/images/server/index_4.png") no-repeat center center;
                    background-size: cover;
                    width: 144px;
                    height: 144px;
                    transition: all 500ms linear;
                    margin-bottom: 30px;

                  }
                  .texts {
                    font-size: 34px;
                    line-height: 48px;
                  }
                }
                .itemOne:hover {
                  .img {
                    background: url("../../assets/images/server/indexSelected_4.png") no-repeat center center;
                    background-size: cover;
                  }
                  .texts {
                    display: inline-block;
                    background-image: linear-gradient(
                      to right,
                      #25a9e0,
                      #187aba
                    ); //最后三行为文字渐变CSS
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                  }
                }
                .itemTwo {
                  width: 310px;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  .img {
                    background: url("../../assets/images/server/index_5.png") no-repeat center center;
                    background-size: cover;
                    width: 144px;
                    height: 144px;
                    transition: all 500ms linear;
                    margin-bottom: 30px;

                  }
                  .texts {
                    font-size: 34px;
                    line-height: 48px;
                  }
                }
                .itemTwo:hover {
                  .img {
                    background: url("../../assets/images/server/indexSelect_5.png") no-repeat center center;
                    background-size: cover;
                  }
                  .texts {
                    display: inline-block;
                    background-image: linear-gradient(
                      to right,
                      #25a9e0,
                      #187aba
                    ); //最后三行为文字渐变CSS
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                  }
                }
                .itemThree {
                  width: 310px;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  .img {
                    background: url("../../assets/images/server/index_6.png") no-repeat center center;
                    background-size: cover;
                    width: 144px;
                    height: 144px;
                    transition: all 500ms linear;
                    margin-bottom: 30px;

                  }
                  .texts {
                    font-size: 34px;
                    line-height: 48px;
                  }
                }
                .itemThree:hover {
                  .img {
                    background: url("../../assets/images/server/indexSelected_6.png") no-repeat center center;
                    background-size: cover;
                  }
                  .texts {
                    display: inline-block;
                    background-image: linear-gradient(
                      to right,
                      #25a9e0,
                      #187aba
                    ); //最后三行为文字渐变CSS
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                  }
                }
              }
            }
          }
          .third_content {
            .third_content_top {
              padding: 0 0 20px 0;
              // border-bottom: 1px solid #e7e7e7;
              .rights_top_left {
                    width: 387px;
                    .el-input {
                      max-width: 300px;
                      height: 58px;
                      font-size: 20px;
                      .el-input__inner {
                      text-align: center;
                         height: 58px;
                      }
                    }
                    .el-button--primary {
                      padding: 0;
                      width: 84px;
                      height: 58px;
                      font-size: 20px;
                      color: #FFF;
                      background: linear-gradient(to top right, #25aae1, #197dbe);
                    }

              }
            }
            .third_content_middle {
              margin-bottom: 30px;
            }
            .third_content_bottom {
              .item {
                  font-size: 24px;
                  background-color: #f9f9f9;
                  padding: 25px 20px;
                  border: 1px solid #e7e7e7;
                  margin-bottom: 25px;
                  .question {
                    .question_top {
                      display: flex;
                      .question_top_imgOne{
                        img {
                          width: 30px;
                          height: 23px;
                          vertical-align: bottom;
                        }
                      }
                      .question_one{
                        margin: 0 15px;
                        display: inline-block;
                        background-image: linear-gradient(
                          to right,
                          #25a9e0,
                          #187aba
                        ); //最后三行为文字渐变CSS
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;

                      }
                      .question_text{

                      }
                    }
                  }
                  .time{
                    padding:10px 0 20px 45px ;
                    font-size: 16px;
                  }
                  .answer{
                    .answer_top{
                      display: flex;
                      .answer_top_imgOne {
                        img {
                          width: 24px;
                          height: 24px;
                          vertical-align: bottom;
                        }
                      }
                      .answer_one{
                        margin: 0 15px;
                        display: inline-block;
                        background-image: linear-gradient(
                          to right,
                          #25a9e0,
                          #187aba
                        ); //最后三行为文字渐变CSS
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                      }
                    }
                    .time{
                      padding:10px 0 0 45px ;
                      font-size: 16px;
                    }
                    .answer_text{
                      padding: 10px 0 0 45px;
                    }
                  }
              }
            }
            .first_content_bottom {
              padding: 60px 0;
              .el-pagination {
                .btn-prev, .btn-next {
                  display: none;
                }
                .el-pager {
                  .number {
                    height: 48px!important;
                    line-height: 48px!important;
                    min-width: 48px!important;
                    font-size: 18px!important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .server {
    padding-top: 0;
    .mobileProductTop {
      img {
        height: 4rem;
      }
    }
    .industry_content {
      .section {
        .el-tabs {
          .el-tabs__header {
            margin: 0 0 .5rem;
            line-height: 1.2rem;
            .el-tabs__nav-wrap {
              .el-tabs__nav-prev, .el-tabs__nav-next {
                // display: none;
              }
              .el-tabs__nav-scroll {
                .el-tabs__nav{
                  .el-tabs__item {
                    font-size: .3rem;
                    padding: 0 .25rem;
                  }
                  #tab-1 {
                    padding-left: 0!important;
                  }
                  // .el-tabs__active-bar {
                  //   height: 4px;
                  //   background: linear-gradient(to right, #25aae1, #197dbe);
                  // }
                  // .is-active {
                  //   display: inline-block;
                  //   background-image: linear-gradient(
                  //     to right,
                  //     #25a9e0,
                  //     #187aba
                  //   ); //最后三行为文字渐变CSS
                  //   -webkit-background-clip: text;
                  //   -webkit-text-fill-color: transparent;
                  // }
                }
              }
            }
          }
          .el-tabs__content {
            .first_content {
              .first_content_top {
                display: flex;
                flex-direction: column;
                .lefts {
                  // margin-top: 40px;
                  width:100%;
                  .el-collapse {
                      border: 1px solid #dcdcdc!important;
                      .el-collapse-item {
                          width: 304px;
                          .el-collapse-item__header{
                            display: flex;
                            justify-content: center;
                            width: 100%;
                            font-size: .45rem;
                            height: 1.2rem;
                            .el-collapse-item__arrow  {
                                display: none;
                            }
                          }
                          .el-collapse-item__header.is-active {
                              border-bottom-color: #dcdcdc;
                          }
                      }
                      // .is-active {
                      //   color: #197DBE;
                      // }
                      .el-collapse-item__wrap{
                              border: none;
                              .el-collapse-item__content {
                                  padding-bottom: 0;
                                  div {
                                      padding-left: 0;
                                      font-size: .45rem;
                                      line-height: 1.2rem;
                                      cursor: pointer;
                                      border-bottom: 1px solid #dcdcdc!important;
                                  }
                                  // .selected {
                                  //     color: #197DBE;
                                  // }
                              }
                      }
                  }
                  .el-menu {
                    border: solid 1px #e6e6e6;
                    .el-submenu {
                      .el-submenu__title {
                        // text-align: center;
                        height: 1rem;
                        line-height: 1rem;
                        font-size: .4rem;
                        padding-left: .25rem!important;
                        // color: #197DBE;
                        .el-submenu__icon-arrow {
                          display: none;
                        }
                      }
                      // .el-submenu__title:hover {
                      //   color: #FFF;
                      //   background: linear-gradient(to top right, #25aae1, #197dbe);
                      // }
                      .el-menu {
                        border-left: none;
                        border-right: none;
                        border-bottom: none;
                        .el-submenu {
                          border-bottom: solid 1px #e6e6e6;
                          .el-submenu__title {
                            // text-align: center;
                            height: 1rem;
                            line-height: 1rem;
                            font-size: .4rem;
                            padding-left: .6rem!important;
                            // color: #197DBE;
                            .el-submenu__icon-arrow {
                              display: none;
                            }
                          }
                          .el-menu {
                            .el-menu-item {
                              padding: 0 .8rem!important;
                              // text-align: center;
                              height: 1rem;
                              line-height: 1rem;
                              font-size: .4rem;
                              border-bottom: 1px solid #e6e6e6;
                              // color: #197DBE;
                            }
                            // .el-menu-item:hover {
                            //   background: none;
                            //   color: #197DBE;
                            // }
                            .el-menu-item:nth-child(2) {
                              border-bottom: none;
                            }
                            // .is-active {
                            //   color: #197DBE;
                            // }
                          }
                        }
                        .el-menu-item {
                          padding: 0 .6rem!important;
                          // text-align: center;
                          height: 1rem;
                          line-height: 1rem;
                          font-size: .4rem;
                          border-bottom: 1px solid #e6e6e6;
                          // color: #197DBE;
                        }
                        .el-submenu:nth-child(3) {
                          border: none;
                        }
                      }
                    }
                    // .is-opened {
                    //   color: #FFF;
                    //   background: linear-gradient(to top right, #25aae1, #197dbe);
                    // }
                  }
                }
                .mobile_rights {
                  width: 100%;
                  border: none;
                  padding: 0 ;
                  .rights_top {
                    padding: .7rem 0;
                    display: flex;
                    justify-content: space-between;
                    border-bottom: 1px solid #e7e7e7;
                    .rights_top_left {
                      text-align: center;
                      padding: 0 .8rem;
                      width: 100%;
                      .el-input {
                        max-width: 4rem!important;
                        height: .9rem;
                        font-size: .35rem;
                        .el-input__inner {
                        text-align: center;
                          height: .9rem;
                        }
                      }
                      .el-button--primary {
                        padding: 0;
                        width: 1.3rem;
                        height: .9rem;
                        font-size: .3rem;
                        color: #FFF;
                        background: linear-gradient(to top right, #25aae1, #197dbe);
                      }

                    }
                    .rights_top_right {
                      padding-right: 0;
                      display: flex;
                      flex-direction: column-reverse;
                      span {
                        font-size: 18px;
                      display: inline-block;
                      background-image: linear-gradient(
                        to right,
                        #25a9e0,
                        #187aba
                      ); //最后三行为文字渐变CSS
                      -webkit-background-clip: text;
                      -webkit-text-fill-color: transparent;
                      }

                    }
                  }
                  .rights_bottom {
                    padding-bottom: .3rem;
                    .item {
                      padding:.3rem 0;
                      display: flex;
                      flex-direction: column;
                      align-items: flex-start;
                      border-bottom:1px solid #e7e7e7 ;
                      .rights_bottom_left {
                        .rights_bottom_left_top {
                          .titless {
                            font-size: .35rem;
                          }
                        }
                        .rights_bottom_left_bottom {
                          margin-top: 8px;
                          display: flex;
                          .look, .download, .time{
                            font-size: .3rem;
                          }
                          .download{
                            margin-right: .2rem;
                          }
                        }
                      }
                      .rights_bottom_right {
                        margin-top: .2rem;
                        .el-button--primary {
                          width: 1.4rem;
                          height: 0.8rem;
                          font-size: .3rem;
                          color: #3699FF;
                          background: #fff;
                          padding: 0;
                        }
                      }
                    }
                    .item:nth-child(5n) {
                      border-bottom:none ;
                    }
                    .item:hover {
                      .rights_bottom_left {
                        color: #235E9D;
                      }
                      .rights_bottom_right {
                        .el-button--primary {
                          color: #FFF;
                          background: linear-gradient(to top right, #25aae1, #197dbe);
                        }
                      }

                    }
                  }
                }
              }
              .first_content_bottom {
                padding: 60px 0;
                .el-pagination {
                  .btn-prev, .btn-next {
                    display: none;
                  }
                  .el-pager {
                    .number {
                      height: 48px!important;
                      line-height: 48px!important;
                      min-width: 48px!important;
                      font-size: 18px!important;
                    }
                  }
                }
              }
            }
            .second_content {
              .second_content_top {
                .second_content_top_title {
                  text-align: center;
                  margin-bottom: 0.3rem;
                  span {
                    font-weight: bold;
                    font-size: 0.4rem;
                    display: inline-block;
                    background-image: linear-gradient(
                      to right,
                      #25a9e0,
                      #187aba
                    ); //最后三行为文字渐变CSS
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                  }
                }
                .second_content_top_content {
                  display: flex;
                  justify-content: space-between;
                  .itemOne {
                    width: 32%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .img {
                      background: url("../../assets/images/server/index_1.png") no-repeat center center;
                      background-size: cover;
                      width: 2rem;
                      height: 2rem;
                      transition: all 500ms linear;
                      margin-bottom: .2rem;
                    }
                    .texts {
                      font-size: .3rem;
                      line-height: .45rem;
                    }
                  }
                  .itemTwo {
                    width: 30%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .img {
                      background: url("../../assets/images/server/index_2.png") no-repeat center center;
                      background-size: cover;
                      width: 2rem;
                      height: 2rem;
                      transition: all 500ms linear;
                      margin-bottom: .2rem;
                    }
                    .texts {
                      font-size: .3rem;
                      line-height: .45rem;
                    }
                  }
                  .itemThree {
                    width: 30%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .img {
                      background: url("../../assets/images/server/index_3.png") no-repeat center center;
                      background-size: cover;
                      width: 2rem;
                      height: 2rem;
                      transition: all 500ms linear;
                      margin-bottom: .2rem;
                    }
                    .texts {
                      font-size: .3rem;
                      line-height: .45rem;
                    }
                  }
                }
              }
              .second_content_bottom {
                padding-bottom: 60px;
                .second_content_bottom_title {
                  text-align: center;
                  margin: .4rem 0 .3rem 0;
                  span {
                    font-weight: bold;
                    font-size: 0.4rem;
                    display: inline-block;
                    background-image: linear-gradient(
                      to right,
                      #25a9e0,
                      #187aba
                    ); //最后三行为文字渐变CSS
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                  }
                }
                .second_content_bottom_content {
                  display: flex;
                  justify-content: space-between;
                  .itemOne {
                    width: 30%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .img {
                      background: url("../../assets/images/server/index_4.png") no-repeat center center;
                      background-size: cover;
                      width: 2rem;
                      height: 2rem;
                      transition: all 500ms linear;
                      margin-bottom: .2rem;

                    }
                    .texts {
                      font-size: .3rem;
                      line-height: .45rem;
                    }
                  }
                  .itemTwo {
                    width: 30%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .img {
                      background: url("../../assets/images/server/index_5.png") no-repeat center center;
                      background-size: cover;
                      width: 2rem;
                      height: 2rem;
                      transition: all 500ms linear;
                      margin-bottom: .2rem;
                    }
                    .texts {
                      font-size: .3rem;
                      line-height: .45rem;
                    }
                  }
                  .itemThree {
                    width: 30%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .img {
                      background: url("../../assets/images/server/index_6.png") no-repeat center center;
                      background-size: cover;
                      width: 2rem;
                      height: 2rem;
                      transition: all 500ms linear;
                      margin-bottom: .2rem;

                    }
                    .texts {
                      font-size: .3rem;
                      line-height: .45rem;
                    }
                  }
                }
              }
            }
            .mobile_third_content {
              .third_content_top {
                padding: 0 ;
                // border-bottom: 1px solid #e7e7e7;
                .rights_top_left {
                      padding: 0 .5rem;
                      // width: 387px;
                      .el-input {
                        max-width: 4.5rem;
                        height: .8rem;
                        font-size: .3rem;
                        .el-input__inner {
                        text-align: center;
                          height: .8rem;
                        }
                      }
                      .el-button--primary {
                        padding: 0;
                        width: 1.2rem;
                        height: .8rem;
                        font-size: .3rem;
                        color: #FFF;
                        background: linear-gradient(to top right, #25aae1, #197dbe);
                      }

                }
              }
              .third_content_middle {
                margin-bottom: 0;
                img {
                  width: 100%;
                  padding: .25rem 0;
                }
              }
              .third_content_bottom {
                .item {
                    font-size: .32rem;
                    background-color: #f9f9f9;
                    padding: .3rem .2rem;
                    border: 1px solid #e7e7e7;
                    margin-bottom: .5rem;
                    .question {
                      .question_top {
                        display: flex;
                        .question_top_imgOne{
                          img {
                            width: 20px;
                            height: 15px;
                            vertical-align: middle;
                          }
                        }
                        .question_one{
                          width: 1.5rem;
                          margin: 0 .15rem;
                          display: inline-block;
                          background-image: linear-gradient(
                            to right,
                            #25a9e0,
                            #187aba
                          ); //最后三行为文字渐变CSS
                          -webkit-background-clip: text;
                          -webkit-text-fill-color: transparent;

                        }
                        .question_text{

                        }
                      }
                    }
                    .time{
                      padding: 0.1rem 0 0.2rem 0.55rem;
                      font-size: .3rem;
                    }
                    .answer{
                      .answer_top{
                        display: flex;
                        .answer_top_imgOne {
                          img {
                            width: 17px;
                            height: 17px;
                            vertical-align: middle;
                          }
                        }
                        .answer_one{
                          margin: 0 .15rem;
                          display: inline-block;
                          background-image: linear-gradient(
                            to right,
                            #25a9e0,
                            #187aba
                          ); //最后三行为文字渐变CSS
                          -webkit-background-clip: text;
                          -webkit-text-fill-color: transparent;
                        }
                      }
                      .time{
                        padding: 0.1rem 0 0 0.55rem;
                        font-size: .3rem;
                      }
                      .answer_text{
                        padding: .1rem .5rem 0 .5rem;
                      }
                    }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>